import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  IconShare,
} from '../../components';
import footerLogo from '../../assets/footerLogo.png';
import blueLogo from '../../assets/blueLogo.png';

import css from './Footer.module.css';
import moment from 'moment';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteYoutubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink key="linkToYoutube" href={siteYoutubePage} className={css.icon} title={goToFb}>
      <IconShare type="Youtube" className={css.youtubeIcon} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, landingLogo, ListingType = 'diyer' } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const currentYear = moment().format('YYYY');
  const previousYear = moment().subtract(1, 'years').format('YYYY');
  return (
    <div className={landingLogo ? classes : css.whiteBackground}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
          <div className={classNames(css.footerLinksWrapper,css.links)}>
            <div className={!landingLogo ? css.organization : css.withLogin} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <img src={landingLogo ? footerLogo : blueLogo} />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                {landingLogo ? null : <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>}
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={landingLogo ? css.copyrightLink : css.copyright}>
                    <FormattedMessage id="Footer.copyright" values={{currentYear,previousYear}}  />
                  </NamedLink>
                </p>
              </div>
            </div>
            {/* <div className={css.footerLinksWrapper}> */}
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  {/* {ListingType == 'Pro' ? <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li> : null} */}
                  <li className={css.listItem}>
                    <NamedLink name="CMSPage" params={{ pageId: 'about' }} className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <a className={css.link} target='blank' href='https://theprojectgarage.ca/p/faqs'> <FormattedMessage id="Footer.toFAQPage" /></a>
                  </li>
                  <li className={css.listItem}>
                    <a className={css.link} target='blank' href='https://www.blog.theprojectgarage.ca'> <FormattedMessage id="Footer.blog" /></a>
                  </li>
                  {/* <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'about' }}
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li> */}
                </ul>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <a className={css.link} target='blank' href='https://theprojectgarage.ca/p/youbetwevet'> <FormattedMessage id="Footer.vetPolicy" /></a>
                  </li>
                  <li className={css.listItem}>
                    <a className={css.link} target='blank' href='https://theprojectgarage.ca/p/antibullying'> <FormattedMessage id="Footer.antiBulliying" /></a>
                  </li>
                  <li className={css.listItem}>
                    <a className={css.link} target='blank' href='https://theprojectgarage.ca/p/waiver'> <FormattedMessage id="Footer.waiver" /></a>
                  </li>
                </ul>
              </div>

              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            {/* </div> */}
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);